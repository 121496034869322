<template>
  <el-dialog
    v-draggable
    :visible.sync="visible"
    :title="title"
    :width="Number(options.canvasWidth) + 'px'"
    :close-on-click-modal="false"
    z-index="999999"
    append-to-body
    top="2vh"
    class="el-dialog5"
    :before-close="closeDialog"
  >

    <div class="questM" v-if="params.testPaper==1">
<!--      <div class="questM-A" v-if="questionData">-->
      <div class="questM-A">
        <!--不含语篇内容-->
        <div class="folderItems" v-if="a === 'noDiscourse'">

          <!--题号-->
          <div class="questionTitle">
            <div class="T-a">{{questionData.questionNo}}</div>
            <div class="T-b" v-if="questionData.score !==''">{{questionData.score}}分</div>
          </div>
          <!--选择题-->
          <div class="ContentStem" >
            <div class="stem1" v-html="questionData.content.docHtml"></div>
            <div class="stem2">
            </div>
          </div>
        </div>
        <!--含语篇内容-->

        <div class="DiscourseItems" v-if ="a !== 'noDiscourse'">
          <div class="DiscourseBox" >
            <el-scrollbar style="height:100%;" v-if="questionDataRet != null" >
              <div v-html="(questionDataRet.discourseContent == undefined || questionDataRet.discourseContent == null ) ? '' : questionDataRet.discourseContent" style="padding-right: 10px; box-sizing: border-box"></div>
            </el-scrollbar>
          </div>
          <div class="StemBox" v-if=" questionDataRet != null && questionDataRet.discourseList != undefined && questionDataRet.discourseList != null">
            <div class="folderItemsD"  v-for="Disitem in questionDataRet.discourseList" :key="Disitem.id">
              <div class="questionTitle">
                <div class="T-a">{{Disitem.content.topicNumber}}</div>
                <div class="T-b" v-if="Disitem.score !==''">{{Disitem.score}}分</div>
              </div>
              <!--题干和选项   选择题-->
              <div class="ContentStem">
                <div>
                  <!--选择题-->
                  <div v-if="Disitem.content.list[0].testSelections !=undefined">
                    <div class="stem3">
                      <div v-html="Disitem.content.docHtmlNoOption"></div>
                    </div>
                    <div class="stem4">
                      <div v-for="ditems in Disitem.content.list[0].testSelections" :key="ditems.id">
                        <div v-html="ditems.selectionHTML"></div>
                      </div>
                    </div>
                  </div>
                  <!--非选择题-->
                  <div class="stem3" v-else>
                    <div class="stem3">
                      <div v-html="Disitem.content.docHtml"></div>
                    </div>
                  </div>


                  <div class="OpeRaTion" v-if="!isShowBtn">

                  </div>
                </div>

                <!--                                                            <div class="questionInfo stem" v-html="Disitem.content.strSimpleJson"></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="questM-B">
        <sketchpad :testPaperA="params.testPaper" :imgId="imgId" :stuAnswers="stuAnswers" @handleClose="saveAsImg"></sketchpad>
      </div>
    </div>
    <div class="questD" v-else>
      <sketchpad :testPaperA="params.testPaper" :imgId="imgId" :stuAnswers="stuAnswers" @handleClose="saveAsImg"></sketchpad>
    </div>

  </el-dialog>
</template>
<script>
import sketchpad from "./sketchpad.vue";

export default {
  name: "c-sign-canvas",
  props: {
    questionId: {
      default: 0,
      type: Number,
    },
    params: {
      type: Object,
    },
    parentId: {
      default: 0,
      type: Number,
    },
    question:[],
    title: {
      default: "答题卡写字板",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    isAdd: {
      default: true,
      type: Boolean,
    },
    imgId: {
      default: 'img_001_',
      type: String,
    },
    stuAnswers: {
      type: Object,
    },
  },
  components: {
    sketchpad,
  },
  data() {
    return {
      a:'',
      b: '',
      value: null,
      isEdit: false,
      questionData:null,
      questionDataRet:null,
      options: {
        lastWriteSpeed: 1, //书写速度 [Number] 可选1
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 1600, //canvas宽高 [Number] 可选
        canvasHeight: 300, //高度  [Number] 可选
        isShowBorder: true, //是否显示边框 [可选]
        bgColor: "#e2f7f9", //背景色 [String] 可选 注:这背景色仅仅只是canvas背景,保存的图片仍然是透明的
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: "#7ba4a7", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
    };
  },
  created() {

    // this.question.slice(1,10).forEach((ele) => {
    //   this.Qlist=ele.list
    //
    //   console.log(this.Qlist,'this.questionNo2')
    //   this.Qlist.forEach((ele) => {
    //     if(ele.isDiscourse==0) {
    //       if (this.questionNo == ele.questionNo){
    //         this.questionData =ele
    //         this.DiscourseData =this.Qlist
    //       }
    //     }else {
    //       console.log(this.questionNo,"000000000000000000")
    //       console.log(this.questionNo,"77777777777777777")
    //     }
    //
    //   })
    //
    // })
  },
  mounted() {
    this.$nextTick(()=>{
     // console.log(this.parentId,"666666")
      let anster = [] //非语篇
      let ansterChilder = [] //语篇
      this.question?.forEach((ele,index) => {
        if (ele.list !== undefined && ele.list.length !== 0) {

          ele.list.forEach((ele1,index1)=>{
            if (this.parentId == null){
              if (Number(this.questionId) === Number(ele1.id)) {

                this.a = ele1.contentMap === null ? 'noDiscourse' : '123'
                this.questionData= ele1

              }
            } else {
            //  console.log(this.parentId,"获取语篇的parentId-10000")
              if (ele1.contentMap != null) {

                if (ele1.contentMap.discourseList.length > 0 ) {
                  ele1.contentMap.discourseList.forEach((ele5,index5)=>{
                   // console.log(ele5.parentId,'ele5-838383')
                    if (Number(this.parentId) === Number(ele5.parentId)) {
                      this.questionDataRet= ele1.contentMap
                      console.log(this.questionDataRet,"2022-06-19-99999999")
                    //  console.log(this.parentId,"获取语篇的parentId-99999999")

                    }
                  })
                }

              }

              // let contentMapD = ele1

              // for(let contentMapD of ele1.contentMap) {
              //     console.log(contentMapD,'928282828')
              // }

              // ele1.contentMap.discourseList.forEach((ele4,index4)=>{
              //   console.log(ele4,"语篇-ttiti")
              // })
              // ele.list.forEach((ele1,index1)=>{
              //
              // })
              // if (Number(this.parentId) === Number(ele1.id)) {
              //
              //   console.log(this.parentId,"获取语篇的parentId-99999999")
              //
              // }
            }

          })
        }
      })
      anster.forEach((ele,index) =>{

      })

    })
  },
  watch: {
    question: {
      handler() {
        this.question.map(item => {
          if (item.list != null) {
            item.list.map(it => {
              if (it.isDiscourse == 1) {
                if (it.contentMap.discourseList != null) {
                  it.contentMap.discourseList.map(i => {
                    i.content = JSON.parse(i.content)
                  })
                }
              } else {
                it.content = JSON.parse(it.content)
              }
            })
          }
        })
      },
    }

  },
  methods: {
    /**
     * 清除画板
     */
    canvasClear() {
      this.$refs.SignCanvas.canvasClear();
    },
    closeDialog() {
      this.$emit("update:visible", false);
    },

    /**
     * 保存图片
     */
    saveAsImg(img,canvas) {
      // const img = this.$refs.SignCanvas.saveAsImg();
      this.$emit("change", img,canvas);
      this.closeDialog();
    },

    /**
     * 下载图片
     */
    downloadSignImg() {
      this.$refs.SignCanvas.downloadSignImg();
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.questM {
  width: 100%;
  margin: auto;
  overflow: hidden;

  .questM-A {
    width: 20%;
    box-sizing: border-box;
    padding-right: 20px;
    display: inline-flex;

    float: left;
    .folderItems {
      width: 100%;
      position: relative;
      cursor: pointer;
      border: 1px solid #E6E6E6;
      padding: 10px 0px 0 0px;
      box-sizing: border-box;
      font-size: 12px;
      padding-top: 50px;
      border-radius: 8px;
      color: #666;
      font-weight: normal;
      font-family: MicrosoftYaHei;
      margin: auto 0px 20px auto;
      /* 题号 */
      .questionTitle {
        width: 97%;
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        font-size: 14px;
        color: #fff;
        position: absolute;
        margin-top: -40px;
        font-weight: normal;

        .T-a {
          height: 30px;
          line-height: 30px;
          padding-right: 10px;
          box-sizing: border-box;
          border-radius: 0 30px 30px 0;
          background: #0090DA;
          left: 0px;
          padding-left: 10px;
          position: absolute;
          z-index: 99;
          font-size: 18px;
          color: #fff;
        }

        .T-b {
          width: 60px;
          height: 30px;
          line-height: 30px;
          text-align: right;
          color: #0090DA;
          padding-left: 20px;
          position: absolute;
          z-index: 99;
          right: 0;
          font-size: 14px;
        }
      }

      /* 题干和选项 */
      .ContentStem {
        width: 100%;
        padding: 0 9px;
        box-sizing: border-box;
        word-wrap: break-word;
        word-break: normal;
        display: block;

        .stem1 {
          width: 100%;
          padding-right: 20px;
          box-sizing: border-box;
          float: left;
        }

        .stem2 {
          width: 100%;
          float: left;
          word-break: keep-all;
          word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
          white-space: pre-wrap; //只对中文起作用，强制换行。
          text-align: justify; //css英文语句的两端对齐：
          text-justify: inter-ideograph;
        }

        .stem3 {
          width: 100%;
          padding-right: 20px;
          box-sizing: border-box;
          float: left;
          word-wrap: break-word;
          white-space: normal;
          word-break: break-all;
          word-break: normal;
        }

        .stem4 {
          width: 50%;
          float: left;
          word-break: keep-all;
          word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
          white-space: pre-wrap; //只对中文起作用，强制换行。
          text-align: justify; //css英文语句的两端对齐：
          text-justify: inter-ideograph;
        }
      }
      .checkedItem {
        position: absolute;
        top: 0;
        right: 0;
      }


      .answerInfo {
        font-size: 12px;
        line-height: 20px;

        .answerAna {
          font-size: 12px;
          color: #07C8C8;

          span {
            color: #343434;
          }
        }
      }
    }
    /* 含语篇内容 */
    .DiscourseItems {
      width: 96%;
      height: 670px;
      background: #F3F3F3;
      border: 2px solid #DDDDDD;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 8px;
      padding: 0 0px;
      margin: auto auto 20px auto;
      padding: 10px;
      overflow: hidden;
      box-sizing: border-box;

      .homework-title {
        width: 96%;
        height: 50px;
        line-height: 50px;
        text-indent: 10px;
        font-size: 16px;
        font-weight: bold;
        background: #F3F3F3;
        border: 2px solid #DDDDDD;
        opacity: 1;
        border-radius: 8px;
      }

      /* 语篇内容 */
      .DiscourseBox {
        width: 100%;
        height: 300px;
        margin: auto;
        padding: 0 10px 0 0;
        box-sizing: border-box;
        overflow-y: auto;
        word-wrap: break-word;
        white-space: normal;
        word-break: break-all;
        word-break: normal;
      }

      /* 题干和内容 */
      .StemBox {
        width: 98%;
        height:62%;
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        margin: 10px auto auto auto;
        overflow-y: auto;

        .folderItemsD {
          position: relative;
          cursor: pointer;
          border: 1px solid #E6E6E6;
          padding: 10px 0px 0 0px;
          box-sizing: border-box;
          font-size: 12px;
          padding-top: 50px;
          color: #666;
          font-weight: normal;
          font-family: MicrosoftYaHei;
          margin: auto auto 20px auto;
          /* 题号 */
          .questionTitle {
            width: 97%;
            height: 30px;
            line-height: 30px;
            box-sizing: border-box;
            font-size: 14px;
            color: #fff;
            position: absolute;
            margin-top: -40px;
            font-weight: normal;

            .T-a {
              padding-right: 10px;
              box-sizing: border-box;
              height: 30px;
              line-height: 30px;
              background: #0090DA;
              border-radius: 0 30px 30px 0;
              left: -10px;
              padding-left: 20px;
              position: absolute;
              z-index: 99;
              font-size: 18px;
              color: #fff;
            }

            .T-b {
              width: 60px;
              height: 30px;
              line-height: 30px;
              text-align: right;
              color: #0090DA;
              padding-left: 20px;
              position: absolute;
              z-index: 99;
              right: 0;
              font-size: 14px;
            }
          }

          /* 题干和选项 */
          .ContentStem {
            width: 100%;
            padding: 0 9px;
            box-sizing: border-box;
            text-align: left;
            font-size: 14px;
            word-wrap: break-word;
            white-space: normal;
            word-break: break-all;
            word-break: normal;

            .stem1 {
              width: 100%;
              padding-right: 20px;
              box-sizing: border-box;
              text-align: left;
              word-wrap: break-word;
              white-space: normal;
              word-break: break-all;
              float: left;
            }

            .stem2 {
              width: 100%;
              float: left;
              word-break: keep-all;
              word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
              white-space: pre-wrap; //只对中文起作用，强制换行。
              text-align: justify; //css英文语句的两端对齐：
              text-justify: inter-ideograph;
            }
          }

          .typeList {
            margin-top: 10px;

            .typesBody {
              width: 100%;
              padding: 10px 10px;
              box-sizing: border-box;
              display: inline-flex;
              background-color: #EBF5FB;
              position: relative;

              .answerAna {
                width: 70%;
                float: left;
                color: #585858;
                display: flex;
                float: left;
                font-family: 方正书宋_GBK;
                font-size: 14px;
                vertical-align: baseline;
                position: relative;

                .werAna-A {
                  width: 100px;
                  float: left;
                  position: relative;
                }

                .werAna-B {
                  width: 70%;
                  padding-left: 100px;
                  box-sizing: border-box;
                  float: left;
                }
              }


              .ty01 {
                width: 100px;
                padding-left: 30px;
                color: #BBBBBB;
                box-sizing: border-box;
                cursor: pointer;
                position: absolute;
                right: 0;
                display: flex;

                i {
                  font-size: 14px;
                }
              }

              .ty02 {
                height: 40px;
                float: left;

                span {
                  margin: 0px 10px;
                }

              }

              .ty03 {
                width: 180px;
                height: 40px;
                float: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                  margin: 0px 10px;
                }

                margin-right: 10px;
              }

              .ty04 {
                width: 180px;
                height: 40px;
                float: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .ComH_fade {
              border-top: 1px solid #ddd;
              background: #EBF5FB;
              font-size: 14px;
              padding: 10px;
              box-sizing: border-box;

              .ComH_fade_It {
                width: 100%;
                display: flex;
                position: relative;
                margin-bottom: 10px;

                .werAna-1 {
                  width: 100px;
                  float: left;
                  color: #0090DA;
                  position: absolute;
                }

                .werAna-2 {
                  width: 100%;
                  padding-left: 100px;
                  box-sizing: border-box;
                  float: left;
                }
              }

            }

            .types {
              width: 100%;
              height: 40px;
              line-height: 40px;
              background-color: #EBF5FB;

              .answerAna {
                width: 70%;
                float: left;
                color: #585858;
                float: left;
                display: flex;
                font-size: 14px;
                vertical-align: baseline;

                .werAna-A {
                  width: 100px;
                  float: left;
                }

                .werAna-B {
                  width: 70%;
                  float: left;
                }
              }

              .ty01 {
                width: 100px;
                height: 40px;
                padding-left: 30px;
                color: #BBBBBB;
                box-sizing: border-box;
                cursor: pointer;
                float: right;
                display: flex;

                i {
                  font-size: 14px;
                }
              }

              .ty02 {
                height: 40px;
                float: left;

                span {
                  margin: 0px 10px;
                }

              }

              .ty03 {
                width: 180px;
                height: 40px;
                float: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                  margin: 0px 10px;
                }

                margin-right: 10px;
              }

              .ty04 {
                width: 180px;
                height: 40px;
                float: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .checkedItem {
            position: absolute;
            top: 0;
            right: 0;
          }


        }
      }
    }
  }
  .questM-B {
    width: 80%;
    overflow: hidden;
    float: right;
  }
}
.questD {
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.title {
  padding: 20px;

  text-align: center;
}

.el-dialog__header {
  padding: 20px 20px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  .el-dialog__title {
    position: absolute;
    margin-top: -10px;
  }
}
.sign-canvas-content {
  max-height: 70vh;
  overflow: auto;
}
.sign-canvas {
  display: block;
  margin: 0 auto;
}
.view-image {
  display: block;
  margin: 20px auto;
}

.config {
  // width: 350px;
  // margin: 20px auto;
  .ul-config {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .li-c {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      .item-label {
        font-size: 14px;
      }
      .item-content {
        margin-left: 10px;
      }
    }
  }
}
.el-dialog__footer {
  border-top: 1px solid #eaeefb;
}
.el-input-number ::v-deep.el-input-number__decrease, .el-input-number ::v-deep.el-input-number__increase {
  display: inline-block;
  height: 38px;
  [class*=" el-icon-"], [class^=el-icon-] {
    line-height: 38px !important;
  }
}


</style>
