//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 学历案目录：
export function queryResourceObj(zid) {

    return request({
        url: '/study/resources/queryResourceDetail/'+zid,   // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 购买单个学历案：
export function buyOneObj(obj) {
    return request({
        url: '/study/transactions/buyOne',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}

// 学历案详情图片查询：
export function queryXlaImgObj(obj,data) {
    return request({
        url: `/study/resources/queryXlaImg/${obj.id}`, // url = base url + request url
        method: 'post',
        data: data,
    })
}
// 学历案详情-试题与答题卡V2：
export function queryXlaDetailV2Obj(obj,qType) {
    console.log(qType,'000001')
    return request({
        url: `/study/resources/queryXlaDetailV2/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.type}`,    // url = base url + request url
        //url: '/study/resources/studyXla/'&bookId/&folderId,    // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 学历案详情-轮播图和试题：
export function studyXlaObj(obj,qType) {
    console.log(qType,'000001')
    return request({
        url: `/study/resources/studyXla/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.type}`,    // url = base url + request url
        //url: '/study/resources/studyXla/'&bookId/&folderId,    // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 学历案详情-更换试题：
export function changeQusObj(obj) {
    return request({
        url: `/study/resources/changeQus/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.type}`,    // url = base url + request url
        //url: '/study/resources/studyXla/'&bookId/&folderId,    // url = base url + request url
        //url: `/study/resources/queryResourceDetail/${this.params}`,   // url = base url + request url
        method: 'get',
    })
}
// 保存作业：
export function saveAnaswerObj(obj,paperId) {
    console.log(obj, '===========================')
    return request({
        url: `/study/students/saveAnaswer/${paperId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 提交作业：
export function commitAnaswerObj(obj,paperId) {
    console.log(obj, '===========================')
    return request({
        url: `/study/students/commitAnaswer/${paperId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}

// 增加论坛主题：
export function addTopicObj(obj,bookId,folderId,id) {
    return request({
        url: `/study/resources/addTopic/${bookId}/${folderId}/${id}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询论坛主题列表：
export function TopicListObj(obj,bookId,folderId,id) {
    return request({
        url: `/study/resources/queryTopicList/${bookId}/${folderId}/${id}`,    // url = base url + request url
        method: 'get',
        data: obj,
    })
}
// 查询论坛主题回复列表：
export function TopicReplyListObj(obj) {
    return request({
        url: `/study/resources/queryTopicReplyList/${obj.bookId}/${obj.folderId}/${obj.id}/${obj.topicId}`,    // url = base url + request url
        method: 'get',
    })
}
// 回复论坛主题：
export function replyTopicObj(lu,obj) {
    return request({
        url: `/study/resources/replyTopic/${lu.bookId}/${lu.folderId}/${lu.id}/${lu.topicId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 论坛主题点赞：
export function likeTopicObj(lu) {
    return request({
        url: `/study/resources/likeTopic/${lu.bookId}/${lu.folderId}/${lu.id}/${lu.topicId}/${lu.isLike}`,    // url = base url + request url
        method: 'post',
    })
}
// 查询学习笔记：
export function queryStudyNodesObj(lu) {
    return request({
        url: `/study/students/queryStudyNodes/${lu.resourceId}`,    // url = base url + request url
        method: 'get',
    })
}
// 提交学习笔记：
export function saveStudyNodesObj(lu,obj) {
    return request({
        url: `/study/students/saveStudyNodes/${lu.resourceId}`,    // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 导出学习笔记：
export function exportStudyNodesObj(lu) {
    return request({
        url: `/study/students/exportStudyNodes/${lu.resourceId}`,    // url = base url + request url
        method: 'get',
    })
}
// 检查目录下学历案是否已全购买：
export function checkFolderBuyAllObj(lu) {
    return request({
        url: `/study/students/checkFolderBuyAll/${lu.bookId}/${lu.folderId}`,    // url = base url + request url
        method: 'get',
    })
}



